var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title align-items-center"},[_c('div',{staticClass:"col-md-3 col-xl-6"},[_c('h4',{staticClass:"mb-1 mt-0"},[_vm._v(_vm._s(_vm.pageTitle)+" : "+_vm._s(_vm.currentLocation.name))])]),_c('div',{staticClass:"col-md-9 col-xl-6 text-md-right"},[_c('div',{staticClass:"mt-4 mt-md-0"},[_c('button',{staticClass:"btn btn-dark mr-2 mb-3 mb-sm-0",attrs:{"type":"button"},on:{"click":function($event){return _vm.goback()}}},[_c('i',{staticClass:"mr-1"}),_vm._v(" Go Back ")])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-xl-12"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-horizontal",attrs:{"autocomplete":"nope"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveLocation)}}},[_c('h4',{staticClass:"header-title"},[_vm._v("Location Name")]),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Select Company")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"selectcompany","rules":{ required: true },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticClass:"mb-2",attrs:{"name":"Company"},model:{value:(_vm.currentLocation.companyKeyId),callback:function ($$v) {_vm.$set(_vm.currentLocation, "companyKeyId", $$v)},expression:"currentLocation.companyKeyId"}},[_c('option',{attrs:{"text":"Please Select","value":""}},[_vm._v("Please Select")]),_vm._l((_vm.companyList),function(item,index){return _c('option',{key:index,attrs:{"text":item.name},domProps:{"value":item.keyId}},[_vm._v(_vm._s(item.name))])})],2),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Name")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"locationname","rules":{
                    regex: /^[a-zA-Z0-9 -]+$/,
                    required: true,
                    max: 100,
                  },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"locationname","autocomplete":"nope"},model:{value:(_vm.currentLocation.name),callback:function ($$v) {_vm.$set(_vm.currentLocation, "name", $$v)},expression:"currentLocation.name"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('h4',{staticClass:"header-title"},[_vm._v("Address")]),(!_vm.isEditing)?_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Search Address:")])]),_c('b-col',{attrs:{"sm":"7"}},[_c('addressSuggest',{on:{"getAddress":_vm.getAddress}})],1)],1):_vm._e(),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Country:")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{staticClass:"mb-2",attrs:{"name":"state","disabled":_vm.isEditing},on:{"change":_vm.onCountrySelected},model:{value:(_vm.currentLocation.country),callback:function ($$v) {_vm.$set(_vm.currentLocation, "country", $$v)},expression:"currentLocation.country"}},_vm._l((_vm.countryList),function(item,index){return _c('option',{key:index,attrs:{"text":item.country},domProps:{"value":item.countryCode}},[_vm._v(_vm._s(item.country))])}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("State:")])]),_c('b-col',{attrs:{"sm":"4"}},[_c('ValidationProvider',{attrs:{"rules":{ required: true, max: 100 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-select',{staticClass:"mb-2",attrs:{"name":"state","disabled":_vm.isEditing},model:{value:(_vm.currentLocation.state),callback:function ($$v) {_vm.$set(_vm.currentLocation, "state", $$v)},expression:"currentLocation.state"}},_vm._l((_vm.stateList),function(item,index){return _c('option',{key:index,attrs:{"text":item.state},domProps:{"value":item.stateCode}},[_vm._v(_vm._s(item.state))])}),0),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Address Line 1:")])]),_c('b-col',{attrs:{"sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"addressLine1","rules":{
                    regex: /^[a-zA-Z0-9 \\/-_',.]+$/,
                    required: true,
                    max: 100,
                  },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"addressLine1","disabled":_vm.isEditing,"autocomplete":"nope"},model:{value:(_vm.currentLocation.addressLine1),callback:function ($$v) {_vm.$set(_vm.currentLocation, "addressLine1", $$v)},expression:"currentLocation.addressLine1"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Address Line 2:")])]),_c('b-col',{attrs:{"sm":"5"}},[_c('ValidationProvider',{attrs:{"name":"addressLine2","rules":{
                    regex: /^[a-zA-Z0-9 \\/-_',.]+$/,
                    required: false,
                    max: 100,
                  },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"addressLine2","disabled":_vm.isEditing,"autocomplete":"nope"},model:{value:(_vm.currentLocation.addressLine2),callback:function ($$v) {_vm.$set(_vm.currentLocation, "addressLine2", $$v)},expression:"currentLocation.addressLine2"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("City:")])]),_c('b-col',{attrs:{"sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"city","rules":{
                    regex: /^[a-zA-Z0-9 -]+$/,
                    required: true,
                    max: 100,
                  },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"city","disabled":_vm.isEditing,"autocomplete":"nope"},model:{value:(_vm.currentLocation.city),callback:function ($$v) {_vm.$set(_vm.currentLocation, "city", $$v)},expression:"currentLocation.city"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v("Postcode:")])]),_c('b-col',{attrs:{"sm":"3"}},[_c('ValidationProvider',{attrs:{"name":"Postcode","rules":{ regex: /^[0-9]+$/, required: true, max: 10 },"custom-messages":_vm.customMessages},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"Postcode","disabled":_vm.isEditing,"autocomplete":"nope"},model:{value:(_vm.currentLocation.postcode),callback:function ($$v) {_vm.$set(_vm.currentLocation, "postcode", $$v)},expression:"currentLocation.postcode"}}),_c('span',{staticClass:"text-danger text-sm"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"sm":"3"}},[_c('label',{staticClass:"mt-2 float-right",attrs:{"for":"inputEmail3"}},[_vm._v(" ")])]),_c('b-col',{attrs:{"sm":"3"}},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.saveLocation}},[_vm._v("Submit")])])],1)],1)]}}])})],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }