<template>
  <div>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0">{{ pageTitle }} : {{ currentLocation.name }}</h4>
      </div>
      <div class="col-md-9 col-xl-6 text-md-right">
        <div class="mt-4 mt-md-0">
          <button type="button" @click="goback()" class="btn btn-dark mr-2 mb-3 mb-sm-0">
            <i class="mr-1"></i> Go Back
          </button>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-md-12 col-xl-12">
            <ValidationObserver ref="form" v-slot="{ handleSubmit }">
              <form class="form-horizontal" @submit.prevent="handleSubmit(saveLocation)" autocomplete="nope">
                <h4 class="header-title">Location Name</h4>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">Select Company</label>
                  </b-col>
                  <b-col sm="6">
                    <ValidationProvider v-slot="{ errors }" name="selectcompany" :rules="{ required: true }"
                      :custom-messages="customMessages">
                      <b-form-select v-model="currentLocation.companyKeyId" name="Company" class="mb-2">
                        <option text="Please Select" value="">Please Select</option>
                        <option v-for="(item, index) in companyList" :key="index" :value="item.keyId" :text="item.name">{{
                          item.name }}</option>
                      </b-form-select>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">Name</label>
                  </b-col>
                  <b-col sm="6">
                    <ValidationProvider v-slot="{ errors }" name="locationname" :rules="{
                      regex: /^[a-zA-Z0-9 -]+$/,
                      required: true,
                      max: 100,
                    }" :custom-messages="customMessages">
                      <b-form-input v-model="currentLocation.name" name="locationname" autocomplete="nope"></b-form-input>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <h4 class="header-title">Address</h4>

                <b-row class="mb-3" v-if="!isEditing">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">Search Address:</label>
                  </b-col>
                  <b-col sm="7">
                    <addressSuggest @getAddress="getAddress"></addressSuggest>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">Country:</label>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100 }"
                      :custom-messages="customMessages">
                      <b-form-select v-model="currentLocation.country" name="state" class="mb-2"
                        @change="onCountrySelected" :disabled="isEditing">
                        <option v-for="(item, index) in countryList" :key="index" :value="item.countryCode"
                          :text="item.country">{{ item.country }}</option>
                      </b-form-select>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">State:</label>
                  </b-col>
                  <b-col sm="4">
                    <ValidationProvider v-slot="{ errors }" :rules="{ required: true, max: 100 }"
                      :custom-messages="customMessages">
                      <b-form-select v-model="currentLocation.state" name="state" class="mb-2" :disabled="isEditing">
                        <option v-for="(item, index) in stateList" :key="index" :value="item.stateCode"
                          :text="item.state">{{ item.state }}</option>
                      </b-form-select>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">Address Line 1:</label>
                  </b-col>
                  <b-col sm="5">
                    <ValidationProvider v-slot="{ errors }" name="addressLine1" :rules="{
                      regex: /^[a-zA-Z0-9 \\/-_',.]+$/,
                      required: true,
                      max: 100,
                    }" :custom-messages="customMessages">
                      <b-form-input v-model="currentLocation.addressLine1" name="addressLine1" :disabled="isEditing"
                        autocomplete="nope"></b-form-input>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">Address Line 2:</label>
                  </b-col>
                  <b-col sm="5">
                    <ValidationProvider v-slot="{ errors }" name="addressLine2" :rules="{
                      regex: /^[a-zA-Z0-9 \\/-_',.]+$/,
                      required: false,
                      max: 100,
                    }" :custom-messages="customMessages">
                      <b-form-input v-model="currentLocation.addressLine2" name="addressLine2" :disabled="isEditing"
                        autocomplete="nope"></b-form-input>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">City:</label>
                  </b-col>
                  <b-col sm="3">
                    <ValidationProvider v-slot="{ errors }" name="city" :rules="{
                      regex: /^[a-zA-Z0-9 -]+$/,
                      required: true,
                      max: 100,
                    }" :custom-messages="customMessages">
                      <b-form-input v-model="currentLocation.city" name="city" :disabled="isEditing"
                        autocomplete="nope"></b-form-input>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">Postcode:</label>
                  </b-col>
                  <b-col sm="3">
                    <ValidationProvider v-slot="{ errors }" name="Postcode"
                      :rules="{ regex: /^[0-9]+$/, required: true, max: 10 }" :custom-messages="customMessages">
                      <b-form-input v-model="currentLocation.postcode" name="Postcode" :disabled="isEditing"
                        autocomplete="nope"></b-form-input>
                      <span class="text-danger text-sm">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col sm="3">
                    <label class="mt-2 float-right" for="inputEmail3">&nbsp;</label>
                  </b-col>
                  <b-col sm="3">
                    <button type="button" class="btn btn-primary" @click="saveLocation">Submit</button>
                  </b-col>
                </b-row>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import addressSuggest from '../../../components/addressSuggest'

import {
  companyData
} from '@state/api/company'

import {
  locationData
} from '@state/api/location';

import { treeData } from '@state/api/tree'

import { customMessages } from '@utils/validationmessage'

export default {
  page: {
    title: 'Locations',
    meta: [{
      name: 'My Locations',
      content: ""
    }],
  },
  components: {
    addressSuggest,
  },
  data() {
    return {
      isEditing: false,
      pageTitle: 'Add New Location',
      address: '',
      stateList: [],
      countryList: [],
      currentLocation: {},
      customMessages,
      companyList: []
    }
  },
  computed: {
    locationId() {
      return this.$route.params.id
    },
  },
  async created() {
    this.countryList = await treeData.searchCountry()
    await this.loadLocation()
    await this.loadCompanies();
  },
  methods: {
    async loadCompanies() {
      this.companyList = await companyData.searchCustomers();
    },
    async loadLocation() {
      if (this.locationId === undefined) {
        this.currentLocation = {}
        this.isEditing = false
      } else {
        this.currentLocation = await locationData.searchByKey(this.locationId)
        this.pageTitle = 'Manage'
        this.isEditing = true
      }
    },
    goback() {
      this.$router.back()
    },
    async onCountrySelected() {
      if (this.currentLocation) {
        this.stateList = await treeData.searchState(
          this.currentLocation.country
        )
      }
    },
    async getAddress(address) {
      this.currentLocation = {
        ...this.currentLocation,
        ...address,
      }
      await this.onCountrySelected()
    },
    updateRadius(radius) {
      this.currentLocation.radius = radius
      this.radius = radius
    },
    async bindLocation(id) {
      this.currentLocation = await locationData.searchByKey(id)
    },
    async saveLocation() {
      const success = await this.$refs.form.validate()
      if (!success) {
        return
      }

      let apiResult = null
      if (this.isEditing) {
        apiResult = await locationData.updateLocation(this.currentLocation)
      } else {
        apiResult = await locationData.save(this.currentLocation)
      }
      if (!apiResult.succeeded) {
        this.$vs.notify({
          title: 'Error',
          text: apiResult.Message,
          color: 'danger',
          position: 'top-center',
        })
        return
      }

      this.$vs.notify({
        title: 'Success',
        text: 'Record Saved',
        color: 'success',
        position: 'top-center',
      })
      this.$router.back()
    },
  },
}
</script>
  